<template>
  <div>
    <CompanyInfo
      :images="images"
      :companyBrandColour="companyBrandColour"
      :companyLogo="companyLogo"
    >
      <template #companyName>Shire</template>
      <template #date>2017</template>
      <template #content>
        <ul class="dashed">
          <li>
            We supported the exhibition teams delivering moderated content using our
            supplied interactive keypad voting system at immunodeficiency congresses.
          </li>
          <li>
            We were delighted to educate, engage and entertain delegates against the clock
            as they answered a set of varied medical questions on HyQvia and
            immunodeficiency.
          </li>
          <li>
            We helped the company make a smooth transition by providing continuity as they
            re-branded under the Takeda umbrella in the following year.
          </li>
        </ul>
      </template>
    </CompanyInfo>
  </div>
</template>

<script>
import CompanyInfo from "@/components/Portfolio/CompanyInfo.vue";
export default {
    name: 'ShireItem',
  components: { CompanyInfo },
  data() {
    return {
      companyBrandColour: "#3983C5",
      companyLogo: require("@/assets/images/portfolio/shire-logo.svg"),
      images: [
        {
          id: 0,
          responsiveImages: require("@/assets/images/portfolio/shire/1.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0.0",
          pictureDetails: "EUROPEAN SOCIETY FOR IMMUNODEFICIENCIES (2017) BARCELONA",
        },
        {
          id: 1,
          responsiveImages: require("@/assets/images/portfolio/shire/2.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0.0",
          pictureDetails: "EUROPEAN SOCIETY FOR IMMUNODEFICIENCIES (2017) BARCELONA",
        },
        {
          id: 2,
          responsiveImages: require("@/assets/images/portfolio/shire/3.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0.0",
          pictureDetails: "EUROPEAN SOCIETY FOR IMMUNODEFICIENCIES (2017) BARCELONA",
        },
      ],
    };
  },
};
</script>
